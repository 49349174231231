export const SearchCategoriesToExclude = new Set([
  '63c16fb098cb551b93c87bac', // S_uomo - stg
  '63c16c6f98cb551b93c87ba8', // S_donna - stg
  '63c16fb598cb551b93c87be2', // unisex - stg
  '63c16fb098cb551b93c87bb0', // S_uomo_SG_abbigliamento - stg
  '63c16edf98cb551b93c87bab', // S_donna_SG_abbigliamento - stg
  '63c16fb898cb551b93c87bfc', // S_unisex_SG_abbigliamento - stg
  '63fc786b48ba6d56527c4361', // donna_nuovi_arrivi_settimana - stg
  '63fc788b48ba6d56527c4362', // uomo_nuovi_arrivi_settimana - stg
  '63fc78b448ba6d56527c4363', // man_new_arrivals_month - stg
  '63fc78d948ba6d56527c4364', // woman_new_arrivals_month - stg
  '6411adb7b236a6193344d70e', // donna_nuovi_arrivi_settimana - PRD
  '6411ae08300a22e75b3c7892', // A_uomo_nuovi_arrivi_settimana - PRD
  '6411ae37300a22e75b3c7893', // A_donna_nuovi_arrivi_mese - PRD
  '6411ae66b236a6193344d70f', // uomo_nuovi_arrivi_mese - PRD
  '6411ddd1b236a6193344d710', // S_donna - PRD
  '6411ddd2300a22e75b3c7894', // S_unisex - PRD
  '6411ddef300a22e75b3c7898', // S_uomo - PRD
  '645a20590b973a9bb84c285c', // MD35 - PRD
]);
